import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import {
  InvoiceLabelField,
  InvoiceLabelOptions,
  SelectItemData,
} from "@/types/utils";
import { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";
import { Label } from "@planetadeleste/vue-mc-gw";
import { Response } from "vue-mc";
import { map } from "lodash";

export interface LabelState {
  _fields: InvoiceLabelOptions | null;
}

@Module({
  name: "labels",
  dynamic: true,
  namespaced: true,
  store: store,
})
class Labels extends VuexModule implements LabelState {
  public isLoadingFields = false;

  _fields: InvoiceLabelOptions | null = null;

  get fields(): InvoiceLabelField[] {
    return this._fields
      ? (Object.keys(this._fields) as InvoiceLabelField[])
      : [];
  }

  get fieldOptions(): InvoiceLabelOptions | null {
    return this._fields;
  }

  get items() {
    return this.fieldOptions
      ? map(
          this.fieldOptions,
          (
            sValue: string,
            sKey: InvoiceLabelField
          ): SelectItemData<InvoiceLabelField, string> => {
            return {
              text: sValue,
              value: sKey,
            };
          }
        )
      : [];
  }

  // Updating Loading Fields State
  @Mutation
  setLoadingFieldsState(state: boolean) {
    this.isLoadingFields = state;
  }

  @Action
  async loadFields() {
    // prevent multiple calls
    if (this.isLoadingFields) return;

    this.setLoadingFieldsState(true);

    try {
      const obConfig: AxiosRequestConfig = {
        url: route("labels.fields"),
        method: "GET",
      };
      const obLabel = new Label();
      const obResponse = (await obLabel
        .createRequest(obConfig)
        .send()) as Response<InvoiceLabelOptions>;

      this.setFields(obResponse.getData().data);
    } catch (e) {
      console.error("An error occurred:", e);
    }

    this.setLoadingFieldsState(false);
  }

  @Mutation
  private setFields(obData: InvoiceLabelOptions) {
    this._fields = obData;
  }
}

export const LabelsModule = getModule(Labels);
