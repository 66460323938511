<template>
  <gw-form-observer
    :loading="loading"
    :title="title"
    hide-top-actions
    @cancel="onCancel"
    @save="onSave"
  >
    <v-row>
      <v-col cols="12">
        <form-field-text v-model="obModel.name" label="name" required />
      </v-col>
      <v-col cols="12">
        <form-field-text v-model="obModel.info" label="info" required />
      </v-col>
      <v-col cols="12">
        <label-field-select
          v-model="obModel.field"
          :disabled="!!field"
          required
        />
      </v-col>
    </v-row>

    <template v-if="goBack" #bottom-actions>
      <v-btn color="primary" text @click="onBack">
        {{ $t("back") }}
      </v-btn>
    </template>
  </gw-form-observer>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { Label, LabelData } from "@planetadeleste/vue-mc-gw";
import { Response } from "vue-mc";
import LabelFieldSelect from "@/components/form/fields/LabelFieldSelect.vue";
import { InvoiceLabelField } from "@/types/utils";

@Component({
  components: { LabelFieldSelect },
})
export default class CompanyLabelForm extends Vue {
  @VModel({ type: Object }) obModel!: Label;
  @Prop(String) readonly title!: string;
  @Prop(String) readonly field!: InvoiceLabelField;
  @Prop(Boolean) readonly goBack!: boolean;

  loading = false;

  async onSave() {
    this.loading = true;
    this.obModel.sync();
    const obResponse: Response<LabelData> | null = await this.obModel.store();

    if (obResponse) {
      this.obModel.set(obResponse.getData().data);
    }

    this.loading = false;
    this.$emit("reload");
  }

  onCancel() {
    this.$emit("cancel");
  }

  onBack() {
    this.$emit("back");
  }
}
</script>
