<template>
  <div v-frag>
    <v-list dense flat>
      <v-list-item
        v-for="obItem in items"
        :key="`label-${obItem.value}`"
        class="primary--text"
        color="primary"
        link
        @click="onOpen(obItem.value)"
      >
        <v-list-item-title v-text="obItem.text" />
      </v-list-item>
    </v-list>

    <v-dialog
      v-model="dialog"
      attach="#app"
      max-width="800px"
      open-delay="300"
      scrollable
      transition="dialog-top-transition"
    >
      <v-carousel
        v-if="dialog"
        v-model="carousel"
        :show-arrows="false"
        height="auto"
        hide-delimiters
      >
        <v-carousel-item>
          <v-card
            :loading="loading"
            class="d-flex flex-column"
            flat
            height="100%"
          >
            <v-card-title
              v-if="currentField"
              class="text-h5 font-weight-light"
              v-text="currentField.text"
            />

            <template v-if="hasModel">
              <v-divider />
              <v-card-subtitle v-text="$t('assigned.company.labels')" />
              <v-card-text v-if="modelLabels.length">
                <company-label-list
                  :items="modelLabels"
                  hide-update
                  show-apply
                  @delete="onRemove"
                />
              </v-card-text>

              <v-divider />
              <v-card-subtitle v-text="$t('default.company.labels')" />
            </template>

            <v-card-text>
              <company-label-list
                :items="labels"
                :show-copy="hasModel"
                :hide-delete="hasModel"
                :hide-update="hasModel"
                @copy="onCopy"
                @delete="onRemove"
                @update="onSelect"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                :disabled="loading"
                class="mr-4"
                color="success"
                text
                @click="onCreate"
              >
                {{ $t("create") }}
              </v-btn>
              <v-btn :disabled="loading" color="primary" text @click="onClose">
                {{ $t("close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-carousel-item>

        <v-carousel-item>
          <company-label-form
            v-if="carousel > 0"
            v-model="obModel"
            :field="sCurrentField"
            :go-back="!!labels.length"
            :title="currentField ? currentField.text : undefined"
            @back="onBack"
            @cancel="onClose"
            @reload="onReload"
          />
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { LabelsModule } from "@/store/labels";
import { filter, find, unset } from "lodash";
import { InvoiceLabelField } from "@/types/utils";
import { Customer, Label, LabelCollection } from "@planetadeleste/vue-mc-gw";
import CompanyLabelForm from "@/modules/companies/components/CompanyLabelForm.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import { number } from "mathjs";
import { Product } from "@planetadeleste/vue-mc-shopaholic";
import CompanyLabelList from "@/modules/companies/components/CompanyLabelList.vue";

@Component({
  components: { CompanyLabelList, IconTrash, IconEdit, CompanyLabelForm },
})
export default class CompanyLabelsDialog extends Vue {
  @Prop(String) readonly field!: InvoiceLabelField;
  @Prop(Object) readonly model!: Customer | Product;

  loading = false;
  dialog = false;
  carousel = 0;
  sCurrentField: InvoiceLabelField | undefined | null = null;
  obCollection: LabelCollection = new LabelCollection();
  obModelCollection: LabelCollection = new LabelCollection();
  obModel: Label = new Label();

  get items() {
    return filter(LabelsModule.items, (obItem) => {
      return this.field ? obItem.value === this.field : true;
    });
  }

  get fields() {
    return LabelsModule.fields;
  }

  get currentField() {
    return this.sCurrentField
      ? find(this.items, { value: this.sCurrentField })
      : undefined;
  }

  get labels() {
    return this.obCollection.getModels();
  }

  get modelLabels() {
    return this.obModelCollection.getModels();
  }

  get sModelClassName() {
    return this.model ? this.model.constructor.name : undefined;
  }

  get hasModel(): boolean {
    return !!this.model && !!this.model.id && !!this.sModelClassName;
  }

  onSelect(obLabel: Label) {
    this.obModel = new Label(obLabel.attributes);
    this.carousel = 1;
  }

  /**
   * Removes the provided label.
   *
   * @param {Label} obLabel - The label object to be removed.
   * @returns {Promise<void>} A promise that resolves when the label has been removed.
   */
  async onRemove(obLabel: Label): Promise<void> {
    const bRemove = await this.$confirm(
      this.$t("ask.remove.record") as string,
      { color: "warning" }
    );

    if (bRemove) {
      await obLabel.delete();
      await this.onReload();
    }
  }

  /**
   * Asynchronously opens the dialog for the given invoice label field.
   *
   * @param {InvoiceLabelField} sValue - The invoice label field to open the dialog for.
   *
   * @return {Promise} - A promise that resolves when the dialog is successfully opened.
   */
  async onOpen(sValue: InvoiceLabelField): Promise<void> {
    this.sCurrentField = sValue;
    this.carousel = 0;
    await this.onReload();
    this.dialog = true;
  }

  onCreate() {
    this.obModel = new Label();
    this.carousel = 1;

    this.obModel.set("field", this.sCurrentField);

    if (this.hasModel) {
      this.obModel.set("labelable_id", this.model.id);
      this.obModel.set("labelable_type", this.sModelClassName);
    }
  }

  async onCopy(obModel: Label) {
    if (!this.hasModel) {
      return;
    }

    const obAttr = obModel.attributes;
    obAttr.parent_id = obAttr.id;
    obAttr.labelable_id = this.model.id;
    obAttr.labelable_type = this.sModelClassName;
    unset(obAttr, "id");

    this.loading = true;
    this.obModel = new Label(obAttr);
    this.obModel.sync();
    await this.obModel.store();
    this.loading = false;

    await this.onReload();
  }

  onBack() {
    this.carousel = 0;
    this.obModel = new Label();
  }

  onClose() {
    this.dialog = false;
    this.onBack();
  }

  /**
   * Load labels based on current field and company
   */
  async onReload() {
    await this.loadModelCollection();

    const obFilters: Record<string, any> = {
      field: this.sCurrentField,
      orphan: 1,
    };
    this.loading = true;
    const obCollection = new LabelCollection();
    await obCollection.filterBy(obFilters).fetch();

    // Disable assigned labels into current model
    if (this.obModelCollection.length && obCollection.length) {
      obCollection.each((obModel) => {
        const obChild = this.obModelCollection.find({ parent_id: obModel.id });
        if (obChild) {
          obModel.set("disabled", true);
        }
      });
    }

    this.obCollection.clear();
    this.obCollection.add(obCollection.getModels());
    this.loading = false;
    this.carousel = 0;

    if (!this.obCollection.length) {
      this.onCreate();
    }
  }

  /**
   * Load labels based on current model (Customer or Product)
   */
  async loadModelCollection() {
    if (!this.hasModel) {
      return;
    }

    const obFilters: Record<string, any> = {
      field: this.sCurrentField,
      labelable: [this.sModelClassName, number(this.model.id)],
    };

    if (!this.field) {
      obFilters.orphan = 1;
    }

    this.loading = true;
    const obCollection = new LabelCollection();
    await obCollection.filterBy(obFilters).fetch();

    this.obModelCollection.clear();
    this.obModelCollection.add(obCollection.getModels());
    this.loading = false;
    this.carousel = 0;
  }

  async mounted() {
    if (!this.fields.length) {
      await LabelsModule.loadFields();
    }
  }
}
</script>
